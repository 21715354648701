import $ from 'jquery';

$(document).ready(function() {
    // General function to handle the toggling logic for date input fields
    function handleDateInputs(filterSelect, startDateInput, endDateInput) {
        function toggleDateInputs() {
            const isAllSelected = filterSelect.val() === 'all';
            startDateInput.prop('disabled', !isAllSelected);
            endDateInput.prop('disabled', !isAllSelected);

            if (!isAllSelected) {
                startDateInput.val('');
                endDateInput.val('');
            }
        }

        // Initial check on page load
        toggleDateInputs();

        // Event listener to toggle date input fields on change
        filterSelect.on('change', toggleDateInputs);
    }

    // Appointments filters
    handleDateInputs($('#q_start_date_eq'), $('#q_start_date_gteq'), $('#q_start_date_end_of_day_lteq'));

    // Sales filters
    handleDateInputs($('#q_date_eq'), $('#q_date_gteq'), $('#q_date_end_of_day_lteq'));
});